import BaseApiModule from "./BaseApiModule";

class LandlordApiModule extends BaseApiModule {
  readonly controller: string;

  constructor() {
    super();
    this.controller = "client";
  }

  details(): string {
    const methodUrl = "details";
    return `${this.baseUrl}/${this.controller}/${methodUrl}`;
  }

  availability(projectId: number): string {
    const methodUrl = "availability";
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${projectId}`;
  }

  contact(): string {
    const methodUrl = "request-offer";
    return `${ this.baseUrl }/${ this.controller }/${ methodUrl }`;
  }

  sendRequestOffer(): string {
    const methodUrl = "request-offer";
    return `${ this.baseUrl }/${ this.controller }/${ methodUrl }`;
  }

  getOffer(offerUUID: number): string {
    const methodUrl = "offer";
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${offerUUID}`;
  }
  downloadFile(): string {
    const methodUrl = `download-file`;
    return `${this.baseUrl}/${this.controller}/${methodUrl}`;
  }

  space(spaceId: number): string {
    const methodUrl = "space";
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${spaceId}`;
  }
}

export default LandlordApiModule;
