import { Building, ProjectState } from "./types";

export default {
  buildings: (state: ProjectState): Building[] =>
    state.project?.buildings?.filter((b) => Object.keys(b.packs).length),
  shortlistItems: (
    state: ProjectState,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    const shortlistItems = rootGetters["project/buildings"];

    if (state.shortlistRegistry.type === "digital") {
      const filteredShortListItems = shortlistItems
        .filter((item: any) =>
          state.shortlistRegistry.buildings_ids.includes(item.id)
        )
        .map((item: any) => ({
          ...item,
          packs: item.packs.filter((pack: any) =>
            state.shortlistRegistry.items_ids.includes(pack.id)
          ),
        }));

      return filteredShortListItems?.map((b: any) => {
        let packs = b.packs;
        if (Array.isArray(b.packs)) {
          packs = b.packs.reduce(function (result: any, pack: any) {
            (result[pack.name] = result[pack.name] || []).push(pack);
            return result;
          }, {});
        }
        return {
          ...b,
          packs,
        };
      });
    }

    const filteredShortListItems = shortlistItems
      .filter((item: any) =>
        state.shortlistRegistry.buildings_ids.includes(item.id)
      )
      .map((item: any) => ({
        ...item,
        physicalItems: item.physicalItems.filter((physicalItem: any) =>
          state.shortlistRegistry.typesAreas?.includes(
            `${physicalItem?.physicalItem.type}_${physicalItem?.area?.id}`
          )
        ),
      }));

    const buildingsMetadata = filteredShortListItems.map((b: any) => {
      let physicalItems = b.physicalItems;
      if (Array.isArray(b.physicalItems)) {
        physicalItems = b.physicalItems.reduce(
          (result: any, currentValue: any) => {
            result[currentValue.physicalItem.type] =
              result[currentValue.physicalItem.type] ?? [];
            result[currentValue.physicalItem.type].push(currentValue);
            return result;
          },
          {}
        );

        Object.keys(physicalItems).forEach((area) => {
          physicalItems[area] = physicalItems[area].reduce(
            (result: any, currentValue: any) => {
              result[currentValue.area.name] =
                result[currentValue.area.name] ?? [];
              result[currentValue.area.name].push(currentValue.physicalItem);
              return result;
            },
            {}
          );
        });
      }
      return {
        ...b,
        physicalItems,
      };
    });

    return buildingsMetadata.filter(
      (b: any) => Object.keys(b.physicalItems).length
    );
  },
  shortlistItemsLength: (
    state: ProjectState,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    if (state.shortlistRegistry.type === "digital") {
      return state.shortlistRegistry.items_ids.length;
    } else {
      return state.shortlistRegistry.typesAreas?.length || 0;
    }
    // }
    // const shortlistItems = rootGetters["project/shortlistItems"];
    // if (shortlistItems.length === 0) return 0;

    // let length = 0;
    // console.log({p: shortlistItems});
    // for (const [key, value] of Object.entries(shortlistItems[0].physicalItems)) {
    //   for (const [nestedKey, nestedValue] of Object.entries(value)) {
    //     console.log({nestedValue});
    //   }
    // }
    // // for (const physicalItem of Object.values(
    // //   shortlistItems[0].physicalItems
    // // ) as Record<string, unknown>[]) {
    // //   console.log({object: physicalItem});
    // //   // length += Object.values(physicalItem).length;
    // // }

    // return 1;
  },
  requestOfferItems: (
      state: ProjectState,
      getters: any,
      rootState: any,
      rootGetters: any
  ) => {
    const shortlistItems = rootGetters["project/buildings"];

    if (state.shortlistRegistry.type === "digital") {
      const filteredShortListItems = shortlistItems
          .filter((item: any) =>
              state.shortlistRegistry.buildings_ids.includes(item.id)
          )
          .map((item: any) => ({
            ...item,
            packs: item.packs.filter((pack: any) =>
                state.shortlistRegistry.items_ids.includes(pack.id)
            ),
          }));

      return filteredShortListItems?.map((b: any) => {
        let packs = b.packs;
        if (Array.isArray(b.packs)) {
          packs = b.packs.reduce(function (result: any, pack: any) {
            (result[pack.name] = result[pack.name] || []).push(pack);
            return result;
          }, {});
        }
        return {
          ...b,
          packs,
        };
      });
    }

    const filteredRequestOfferItems = shortlistItems
        .filter((item: any) =>
            state.requestOfferItems.buildings_ids.includes(item.id)
        )
        .map((item: any) => ({
          ...item,
          physicalItems: item.physicalItems.filter((physicalItem: any) =>
              state.requestOfferItems.typesAreas?.includes(
                  `${physicalItem?.physicalItem.type}_${physicalItem?.area?.id}`
              )
          ),
        }));

    const buildingsMetadata = filteredRequestOfferItems.map((b: any) => {
      let physicalItems = b.physicalItems;
      if (Array.isArray(b.physicalItems)) {
        physicalItems = b.physicalItems.reduce(
            (result: any, currentValue: any) => {
              result[currentValue.physicalItem.type] =
                  result[currentValue.physicalItem.type] ?? [];
              result[currentValue.physicalItem.type].push(currentValue);
              return result;
            },
            {}
        );

        Object.keys(physicalItems).forEach((area) => {
          physicalItems[area] = physicalItems[area].reduce(
              (result: any, currentValue: any) => {
                result[currentValue.area.name] =
                    result[currentValue.area.name] ?? [];
                result[currentValue.area.name].push(currentValue.physicalItem);
                return result;
              },
              {}
          );
        });
      }
      return {
        ...b,
        physicalItems,
      };
    });

    return buildingsMetadata.filter(
        (b: any) => Object.keys(b.physicalItems).length
    );
  }
};
