// Actions
const LOAD_ITEMS = "loadItems";
const ADD_DIGITAL_TO_SHORTLIST = "addDigitalToShortlist";
const ADD_PHYSICAL_TO_SHORTLIST = "addPhysicalToShortlist";
const EDIT_DIGITAL_FROM_SHORTLIST = "editDigitalFromShortlist";
const EDIT_PHYSICAL_FROM_SHORTLIST = "editPhysicalFromShortlist";
const REMOVE_DIGITAL_FROM_SHORTLIST = "removeDigitalFromShortlist";
const REMOVE_PHYSICAL_FROM_SHORTLIST = "removePhysicalFromShortlist";
const REMOVE_PHYSICAL_BY_TYPE_FROM_SHORTLIST =
  "removePhysicalByTypeFromShortlist";
const CLEAR_SHORTLIST = "clearShortlist";

// Mutations
const SET_STORE_VALUES = "setStoreValues";
const SET_BUILDING = "setBuilding";
const SET_NEW_ITEM = "setNewItem";
const SET_EDITED_ITEM = "setEditedItem";
const SET_ITEMS = "setItems";
const SET_ITEM_DATES = "setItemDates";
const SET_EMPTY = "setEmpty";
const SET_TOTAL = "setTotal";

// Namespace
const NAMESPACE = "shortlist";

// Cache
const CACHE_NAME = "shortlistCache";

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`;
};

export default {
  action: {
    LOAD_ITEMS,
    ADD_DIGITAL_TO_SHORTLIST,
    ADD_PHYSICAL_TO_SHORTLIST,
    EDIT_DIGITAL_FROM_SHORTLIST,
    EDIT_PHYSICAL_FROM_SHORTLIST,
    REMOVE_DIGITAL_FROM_SHORTLIST,
    REMOVE_PHYSICAL_FROM_SHORTLIST,
    REMOVE_PHYSICAL_BY_TYPE_FROM_SHORTLIST,
    CLEAR_SHORTLIST,
  },
  mutation: {
    SET_STORE_VALUES,
    SET_BUILDING,
    SET_NEW_ITEM,
    SET_EDITED_ITEM,
    SET_ITEMS,
    SET_ITEM_DATES,
    SET_EMPTY,
    SET_TOTAL,
  },
  namespace: NAMESPACE,
  cacheName: CACHE_NAME,
  withNamespace,
};
