import { baseConstants, baseModule } from "./base";
import { projectConstants, projectModule } from "./project";
import { modalConstants, modalModule } from "./modal";
import { requestOfferConstants, requestOfferModule } from "./requestOffer";
import { offerConstants, offerModule } from "./offer";
import { spaceConstants, spaceModule } from "./space";
import { shortlistConstants, shortlistModule } from "./shortlist";

export default {
  [baseConstants.namespace]: baseModule,
  [projectConstants.namespace]: projectModule,
  [modalConstants.namespace]: modalModule,
  [requestOfferConstants.namespace]: requestOfferModule,
  [offerConstants.namespace]: offerModule,
  [spaceConstants.namespace]: spaceModule,
  [shortlistConstants.namespace]: shortlistModule,
};

export {
  baseConstants,
  projectConstants,
  modalConstants,
  requestOfferConstants,
  offerConstants,
  spaceConstants,
  shortlistConstants,
};
