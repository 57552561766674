import { ActionContext } from "vuex";
import constants from "./constants";

const {
  TOGGLE_SIDEBAR,
  TOGGLE_CAMPAIGN_SIDEBAR,
  TOGGLE_REQUEST_OFFER,
  CHANGE_SIDEBAR_STATE,
  LOAD_METADATA,
  CHANGE_PAGE_LOADING_STATE,
  TOGGLE_MOBILE_AVAILABILITY_MENU,
  CHANGE_MOBILE_AVAILABILITY_MENU,
  CHANGE_DOCUMENT_READY_STATE,
  TOGGLE_VIRTUAL_TOUR,
  TOGGLE_VIRTUAL_TOUR_POPUP,
  CLEAR_VIRTUAL_TOUR_POPUP,
  UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED,
  UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED,
} = constants.action;
const {
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_CAMPAIGN_SIDEBAR_STATE,
  SET_REQUEST_OFFER_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_VIRTUAL_TOUR_STATE,
  SET_VIRTUAL_TOUR_POPUP_STATE,
  SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED,
  SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED,
} = constants.mutation;

export default {
  [TOGGLE_SIDEBAR]({ commit, state }: ActionContext<any, any>) {
    commit(SET_SIDEBAR_STATE, !state.sidebarOpen);
  },
  [TOGGLE_MOBILE_AVAILABILITY_MENU]({
    commit,
    state,
  }: ActionContext<any, any>) {
    commit(SET_MOBILE_AVAILABILITY_MENU, !state.mobileAvailabilityMenuOpen);
  },
  [CHANGE_MOBILE_AVAILABILITY_MENU](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_MOBILE_AVAILABILITY_MENU, payload);
  },
  [CHANGE_SIDEBAR_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_SIDEBAR_STATE, payload);
  },
  [TOGGLE_CAMPAIGN_SIDEBAR]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_CAMPAIGN_SIDEBAR_STATE, {
      open: payload.open,
    });
  },
  [TOGGLE_REQUEST_OFFER]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_REQUEST_OFFER_STATE, {
      open: payload.open,
    });
  },
  [LOAD_METADATA]({ commit, state }: ActionContext<any, any>, payload: any) {
    if (payload.general.customJavascriptEnabled) {
      payload.general.customJavascript = "";
    }
    commit(SET_METADATA, payload);
  },
  [CHANGE_PAGE_LOADING_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_PAGE_LOADING_STATE, payload);
  },
  [CHANGE_DOCUMENT_READY_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_DOCUMENT_READY_STATE, payload);
  },
  [TOGGLE_VIRTUAL_TOUR](
    { commit }: ActionContext<any, any>,
    { isActive }: { isActive: boolean }
  ) {
    commit(SET_VIRTUAL_TOUR_STATE, {
      isActive,
    });
  },
  [TOGGLE_VIRTUAL_TOUR_POPUP](
    { commit }: ActionContext<any, any>,
    {
      isActive,
      assets,
      description,
      title,
      code,
    }: {
      isActive: boolean;
      assets: string[];
      description: string;
      title: string;
      code: string;
    }
  ) {
    commit(SET_VIRTUAL_TOUR_POPUP_STATE, {
      isActive,
      assets,
      description,
      title,
      code,
    });
  },
  [CLEAR_VIRTUAL_TOUR_POPUP]({ commit }: ActionContext<any, any>) {
    commit(SET_VIRTUAL_TOUR_POPUP_STATE, {
      isActive: false,
    });
  },
  [UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED](
    { commit }: ActionContext<any, any>,
    { lastItemClicked }: { lastItemClicked: any }
  ) {
    commit(SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED, {
      lastItemClicked,
    });
  },
  [UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED](
    { commit }: ActionContext<any, any>,
    { lastFloorClicked }: { lastFloorClicked: any }
  ) {
    commit(SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED, {
      lastFloorClicked,
    });
  },
};
