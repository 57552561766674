import Vue from "vue";
import Vuex from "vuex";
import modules, {baseConstants, modalConstants, projectConstants, requestOfferConstants, offerConstants, spaceConstants, shortlistConstants} from "./modules";
import {WebApi, WebRequest} from "../helpers/api";
import VueRouter from "vue-router";

Vue.use(Vuex);

const defaultMainState: any = {}

export const createStore = function (router: VueRouter) {
  return new Vuex.Store({
    state: defaultMainState,
    mutations: {},
    actions: {},
    modules: modules,
    getters: {
      constants() {
        return {
          [baseConstants.namespace]: baseConstants,
          [projectConstants.namespace]: projectConstants,
          [modalConstants.namespace]: modalConstants,
          [requestOfferConstants.namespace]: requestOfferConstants,
          [offerConstants.namespace]: offerConstants,
          [spaceConstants.namespace]: spaceConstants,
          [shortlistConstants.namespace]: shortlistConstants,
        };
      },
      requestHelper() {
        return WebRequest
      },
      urlsHelper() {
        return WebApi
      },
      router() {
        return router
      },
      cdnBase(state) {
        return state.base.meta.general.cdnBase;
      },
      getShortlist(state) {
        return state.base.meta.general.shortlist;
      },
      logoImage(state) {
        return state.base.meta.general.logo;
      },
      logoImageWhite(state) {
        return state.base.meta.general.logoWhite;
      },
      shortlistPlaceholder(state) {
        return state.base.meta.general.shortlistPlaceholder;
      },
      getActiveProjectSettings(state) {
        const allSettings = state.base.meta.general.expModelDisplaySettings;
        return allSettings[state.project.activeProject];
      },
      getActiveProjectData(state) {
        return state.project.projects.find((item: any) => (item.slug === state.project.activeProject))
      }
    }
  });
}
