import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { ProjectState } from "./types";

const state: ProjectState = {
  isLoading: true,
  activeProject: "",
  projects: [],
  buildings: [],
  project: {
    isLoading: true,
    floors: [],
    spaces: [],
    buildings: [],
    areas: [],
    packs: [],
    digitalItems: [],
    physicalItems: [],
  },
  infoBox: {
    open: false,
  },
  mediaInfoBox: {
    open: false,
    data: [],
  },
  expandGallery: {
    open: false,
  },
  infoArea: {
    open: false,
  },
  filters: {
    digital: {
      areas: [],
      typeOfPackage: "all",
      advertisingTiming: "all",
      showAllGroupOptions: false,
    },
    physical: {
      areas: [],
      devices: [],
    },
  },
  filtersResults: {
    digital: {
      totalResults: 0,
    },
    physical: {
      totalResults: 0,
    },
  },
  shortlistRegistry: {
    buildings_ids: [],
    items_ids: [],
    physical_item_types: [],
    typesAreas: [],
  },
  requestOfferItems: {
    buildings_ids: [],
    items_ids: [],
    physical_item_types: [],
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
