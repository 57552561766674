import { RequestOfferState } from "./types";
import constants from "./constants";
import { ItemTypes, ShortlistState } from "@/store/modules/shortlist/types";

const {
  SET_CLEAR_REQUEST_OFFER,
  SET_DIGITAL_ITEMS_REQUEST,
  SET_PHYSICAL_ITEMS_REQUEST,
  SET_REQUEST_OFFER_TYPE,
  SET_COMBINED_ITEMS_REQUEST,
  SET_CAMPAIGN_OPTION,
} = constants.mutation;

export default {
  [SET_CLEAR_REQUEST_OFFER](state: RequestOfferState) {
    state.digital = [];
    state.physical = [];
  },
  [SET_DIGITAL_ITEMS_REQUEST](state: RequestOfferState, data: any[]) {
    state.digital = data;
  },
  [SET_PHYSICAL_ITEMS_REQUEST](state: RequestOfferState, data: any[]) {
    state.physical = data;
  },
  [SET_REQUEST_OFFER_TYPE](state: RequestOfferState, data: string) {
    state.requestOfferType = data;
  },
  [SET_COMBINED_ITEMS_REQUEST](state: RequestOfferState, data: any) {
    state.digital = data.digital;
    state.physical = data.physical;
  },
  [SET_CAMPAIGN_OPTION](
    state: ShortlistState,
    {
      buildingId,
      optionIdx,
      startDate,
      endDate,
      checkbox,
      itemType,
    }: {
      buildingId: number;
      optionIdx: string | number;
      startDate?: Date;
      endDate?: Date;
      checkbox?: boolean;
      itemType: ItemTypes;
    }
  ) {
    if (itemType === ItemTypes.DIGITAL) {
      const fBuilding =
        state.digital?.buildings?.find((b: any) => b.id === buildingId) || null;
      if (fBuilding) {
        const itemIndex = fBuilding?.itemsCampaignOptions?.findIndex(
          (obj: any) => obj.packId === optionIdx
        );
        if (itemIndex !== -1) {
          startDate
            ? (fBuilding.itemsCampaignOptions[itemIndex].startDate = startDate)
            : "";
          endDate
            ? (fBuilding.itemsCampaignOptions[itemIndex].endDate = endDate)
            : "";
          checkbox !== undefined
            ? (fBuilding.itemsCampaignOptions[itemIndex].checkbox = checkbox)
            : "";
        }
      }
      return;
    } else if (itemType === ItemTypes.PHYSICAL) {
      const fBuilding =
        state.physical?.buildings?.find((b: any) => b.id === buildingId) ||
        null;
      if (fBuilding) {
        const itemIndex = fBuilding?.itemsCampaignOptions?.findIndex(
          (obj: any) => obj.type === optionIdx
        );
        if (itemIndex !== -1) {
          startDate
            ? (fBuilding.itemsCampaignOptions[itemIndex].startDate = startDate)
            : "";
          endDate
            ? (fBuilding.itemsCampaignOptions[itemIndex].endDate = endDate)
            : "";
          checkbox !== undefined
            ? (fBuilding.itemsCampaignOptions[itemIndex].checkbox = checkbox)
            : "";
        }
      }
      return;
    }
  },
};
