import {
  DigitalItemDate,
  ItemTypes,
  PhysicalTypes,
  ShortlistBuilding,
  ShortlistDigitalOptions,
  ShortlistPhysicalOptions,
  ShortlistState,
} from "./types";
import constants from "./constants";

const {
  SET_STORE_VALUES,
  SET_BUILDING,
  SET_NEW_ITEM,
  SET_EDITED_ITEM,
  SET_ITEMS,
  SET_ITEM_DATES,
  SET_EMPTY,
  SET_TOTAL,
} = constants.mutation;

export default {
  [SET_STORE_VALUES](state: ShortlistState, localStorageItems: any) {
    if (localStorageItems.digital && localStorageItems.digital.buildings) {
      state.digital.buildings = [...localStorageItems.digital.buildings];
    }
    if (localStorageItems.physical && localStorageItems.physical.buildings) {
      state.physical.buildings = [...localStorageItems.physical.buildings];
    }
    if (localStorageItems.total) {
      state.total = localStorageItems.total;
    }
  },
  [SET_BUILDING](
    state: ShortlistState,
    { building, type }: { building: ShortlistBuilding; type: string }
  ) {
    if (type === ItemTypes.DIGITAL) {
      state.digital.buildings.push(building);
    }
    if (type === ItemTypes.PHYSICAL) {
      state.physical.buildings.push(building);
    }
  },
  [SET_NEW_ITEM](
    state: ShortlistState,
    {
      building_id,
      type,
      option,
    }: {
      building_id: number;
      type: string | number;
      option: ShortlistDigitalOptions | ShortlistPhysicalOptions;
    }
  ) {
    if (type === ItemTypes.DIGITAL) {
      const fBuilding =
        state.digital?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.options.push(option);
        const index = fBuilding.itemsCampaignOptions.findIndex(
          (object: any) => {
            if ("pack_id" in option) return object.packId === option.pack_id;
          }
        );
        if (index === -1) {
          fBuilding.itemsCampaignOptions.push({
            packId: "pack_id" in option ? option.pack_id : 0,
            startDate: undefined,
            endDate: undefined,
            checkbox: undefined,
          });
        }
      }
      return;
    }
    if (type === ItemTypes.PHYSICAL) {
      const fBuilding =
        state.physical?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.options.push(option);
        const index = fBuilding.itemsCampaignOptions.findIndex(
          (object: any) => object.type === option.type
        );
        if (index === -1) {
          fBuilding.itemsCampaignOptions.push({
            type: option.type as PhysicalTypes,
            startDate: undefined,
            endDate: undefined,
            checkbox: undefined,
          });
        }
      }
      return;
    }
  },
  [SET_EDITED_ITEM](
    state: ShortlistState,
    {
      oldOption,
      newOption,
      buildingId,
      type,
    }: {
      oldOption: ShortlistDigitalOptions;
      newOption: ShortlistDigitalOptions;
      buildingId: number;
      type: string | number;
    }
  ) {
    if (type === ItemTypes.DIGITAL) {
      const fBuilding =
        state.digital?.buildings?.find((b: any) => b.id === buildingId) || null;
      if (fBuilding) {
        const optionToReplace =
          fBuilding?.options?.find(
            (o) => o.unique_key === oldOption.unique_key
          ) || null;
        const campaignOptionToReplace =
          (fBuilding?.itemsCampaignOptions?.find(
            (o: any) => o.packId === oldOption.unique_key
          ) as DigitalItemDate) || null;
        if (optionToReplace) {
          Object.assign(optionToReplace, newOption);
          campaignOptionToReplace &&
            (campaignOptionToReplace.packId = newOption.pack_id);
        }
      }
      return;
    }
    if (type === ItemTypes.PHYSICAL) {
      return;
    }
  },
  [SET_ITEMS](
    state: ShortlistState,
    {
      building_id,
      type,
      options,
    }: {
      building_id: number;
      type: string | number;
      options: ShortlistDigitalOptions[];
    }
  ) {
    if (type === ItemTypes.DIGITAL) {
      const fBuilding =
        state.digital?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.options = options;

        if (options.length === 0) {
          state.digital.buildings = state.digital.buildings.filter(
            (b: any) => b.id !== building_id
          );
        }
      }
      return;
    }
    if (type === ItemTypes.PHYSICAL) {
      const fBuilding =
        state.physical?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.options = options;
        if (options.length === 0) {
          state.physical.buildings = state.physical.buildings.filter(
            (b: any) => b.id !== building_id
          );
        }
      }
      return;
    }
  },
  [SET_ITEM_DATES](
    state: ShortlistState,
    {
      building_id,
      type,
      itemsCampaignOptions,
    }: {
      building_id: number;
      type: string | number;
      itemsCampaignOptions: any;
    }
  ) {
    if (type === ItemTypes.DIGITAL) {
      const fBuilding =
        state.digital?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.itemsCampaignOptions = itemsCampaignOptions;
      }
      return;
    }
    if (type === ItemTypes.PHYSICAL) {
      const fBuilding =
        state.physical?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      if (fBuilding) {
        fBuilding.itemsCampaignOptions = itemsCampaignOptions;
      }
      return;
    }
  },
  [SET_TOTAL](state: ShortlistState, { type }: { type: string }) {
    if (type === "increment") {
      state.total += 1;
    }
    if (type === "decrement") {
      state.total -= 1;
    }
  },
  [SET_EMPTY](state: ShortlistState) {
    state.digital.buildings = [];
    state.physical.buildings = [];
    state.total = 0;
  },
};
