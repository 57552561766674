import { BaseState } from "./types";
import constants from "./constants";

const {
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_CAMPAIGN_SIDEBAR_STATE,
  SET_REQUEST_OFFER_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_VIRTUAL_TOUR_STATE,
  SET_VIRTUAL_TOUR_POPUP_STATE,
  SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED,
  SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED,
} = constants.mutation;

export default {
  [SET_SIDEBAR_STATE](state: BaseState, isOpen: any) {
    state.sidebarOpen = isOpen;
  },
  [SET_CAMPAIGN_SIDEBAR_STATE](state: BaseState, { open }: { open: boolean }) {
    state.campaignSidebar.open = open;
  },
  [SET_REQUEST_OFFER_STATE](state: BaseState, { open }: { open: boolean }) {
    state.requestOffer.open = open;
  },
  [SET_METADATA](state: BaseState, metaData: any) {
    state.meta = metaData;
  },
  [SET_PAGE_LOADING_STATE](state: BaseState, payload: boolean) {
    state.pageLoading = payload;
  },
  [SET_MOBILE_AVAILABILITY_MENU](state: BaseState, payload: boolean) {
    state.mobileAvailabilityMenuOpen = payload;
  },
  [SET_DOCUMENT_READY_STATE](state: BaseState, payload: boolean) {
    state.documentIsReady = payload;
  },
  [SET_VIRTUAL_TOUR_STATE](
    state: BaseState,
    { isActive = false }: { isActive: boolean }
  ) {
    state.isActiveVirtualTour = isActive;
  },
  [SET_VIRTUAL_TOUR_POPUP_STATE](
    state: BaseState,
    {
      isActive = false,
      assets = [],
      description = "",
      title = "",
      code = "",
    }: {
      isActive: boolean;
      assets: string[];
      description: string;
      title: string;
      code: string;
    }
  ) {
    state.virtualTourPopup.isActive = isActive;
    if (assets.length > 0) {
      state.virtualTourPopup.assets = assets;
    }
    if (description !== "") {
      state.virtualTourPopup.description = description;
    }
    if (title !== "") {
      state.virtualTourPopup.title = title;
    }
    if (code !== "") {
      state.virtualTourPopup.code = code;
    }
  },
  [SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED](
    state: BaseState,
    { lastItemClicked = null }: { lastItemClicked: any }
  ) {
    state.virtualTourLastItemClicked = lastItemClicked;
  },
  [SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED](
    state: BaseState,
    { lastFloorClicked = null }: { lastFloorClicked: any }
  ) {
    state.virtualTourLastFloorClicked = lastFloorClicked;
  },
};
