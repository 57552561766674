import {
  FiltersResults,
  ProjectState,
  ShortlistItem,
  ShortlistRegistry,
} from "./types";
import constants from "./constants";

const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_INFO_BOX_STATE,
  SET_MEDIA_INFO_BOX_STATE,
  SET_EXPAND_GALLERY_STATE,
  SET_INFO_AREA_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_BUILDING,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_SHORTLIST,
  SET_REQUEST_OFFER,
  SET_COUNT_RESULTS,
  SET_EMPTY_REQUEST_OFFER_LIST,
} = constants.mutation;

export default {
  [SET_LANDLORD_DETAILS](state: ProjectState, data: any) {
    state.projects = data.projects;
    state.buildings = data.buildings;
  },
  [SET_PROJECT_AVAILABILITY](state: ProjectState, data: any) {
    state.project.buildings = data.buildings;
    state.project.floors = data.floors;
    state.project.areas = data.areas;
    state.project.packs = data.packs;
    state.project.digitalItems = data.digitalItems;
    state.project.physicalItems = data.physicalItems;
  },
  [SET_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_INFO_BOX_STATE](state: ProjectState, { open }: { open: boolean }) {
    state.infoBox.open = open;
  },
  [SET_MEDIA_INFO_BOX_STATE](
    state: ProjectState,
    { open, data }: { open: boolean; data: any }
  ) {
    state.mediaInfoBox.open = open;
    state.mediaInfoBox.data = data;
  },
  [SET_EXPAND_GALLERY_STATE](state: ProjectState, { open }: { open: boolean }) {
    state.expandGallery.open = open;
  },
  [SET_INFO_AREA_STATE](state: ProjectState, { open }: { open: boolean }) {
    state.infoArea.open = open;
  },
  [SET_PROJECT_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.project.isLoading = payload;
  },
  [SET_ACTIVE_PROJECT](state: ProjectState, payload: string) {
    state.activeProject = payload;
  },
  [SET_ACTIVE_BUILDING](state: ProjectState, payload: string) {
    state.activeBuilding = payload;
  },
  [SET_FILTER_STATE](state: ProjectState, payload: any) {
    if (payload.parentKey === "digital") {
      state.filters.digital[payload.type] = payload.value;
      return;
    }
    if (payload.parentKey === "physical") {
      state.filters.physical[payload.type] = payload.value;
      return;
    }
  },
  [SET_EMPTY_FILTERS](state: ProjectState, payload: any) {
    Object.entries(payload).forEach(([key, value]) => {
      if (key === "digital") {
        Object.entries(payload.digital).forEach(([k, v]) => {
          state.filters.digital[k] = v;
        });
      }
      if (key === "physical") {
        Object.entries(payload.physical).forEach(([k, v]) => {
          state.filters.physical[k] = v;
        });
      }
    });
  },
  [SET_SHORTLIST](state: ProjectState, payload: ShortlistRegistry) {
    state.shortlistRegistry = payload;
  },
  [SET_REQUEST_OFFER](state: ProjectState, payload: any) {
    state.requestOfferItems = payload;
  },
  [SET_COUNT_RESULTS](state: ProjectState, payload: FiltersResults) {
    if (
      payload &&
      payload.physical &&
      payload.physical.totalResults !== undefined &&
      payload.physical.totalResults !== null
    ) {
      if (!state.filtersResults.physical) return;
      state.filtersResults.physical.totalResults =
        payload.physical?.totalResults;
    }
    if (
      payload &&
      payload.digital &&
      payload.digital.totalResults !== undefined &&
      payload.digital.totalResults !== null
    ) {
      if (!state.filtersResults.digital) return;
      state.filtersResults.digital.totalResults = payload.digital?.totalResults;
    }
  },
  [SET_EMPTY_REQUEST_OFFER_LIST](
    state: ProjectState,
    payload: { reset: boolean }
  ) {
    if (payload.reset) {
      state.requestOfferItems.items_ids = [];
      state.requestOfferItems.buildings_ids = [];
      state.requestOfferItems.type = "";
      state.requestOfferItems.physical_item_types = [];
      state.requestOfferItems.typesAreas = [];
    }
  },
};
