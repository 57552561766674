import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { BaseState } from "./types";

const state: BaseState = {
  sidebarOpen: false,
  mobileAvailabilityMenuOpen: false,
  meta: {},
  pageLoading: true,
  documentIsReady: false,
  campaignSidebar: {
    open: false,
  },
  landlordDetails: {},
  requestOffer: {
    open: false,
  },
  isActiveVirtualTour: false,
  virtualTourLastItemClicked: null,
  virtualTourLastFloorClicked: "",
  virtualTourPopup: {
    assets: [],
    title: "",
    description: "",
    code: "",
    isActive: false,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
