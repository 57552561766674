import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { ShortlistState } from "./types";

const state: ShortlistState = {
  digital: {
    buildings: [],
  },
  physical: {
    buildings: [],
  },
  total: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
