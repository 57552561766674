// Actions
const CLEAR_REQUEST = "clearRequest";
const SINGLE_REQUEST_OFFER = "singleRequestOffer";
const MULTIPLE_REQUEST_OFFER = "multipleRequestOffer";
const CHANGE_MODAL_REQUEST_TYPE = "changeModalRequestType";
const EDIT_DIGITAL_FROM_REQUEST_OFFER = "editDigitalFromRequestOffer";
const SELECT_CAMPAIGN_OPTION = "selectCampaignOption";

// Mutations
const SET_CLEAR_REQUEST_OFFER = "setClearRequestOffer";
const SET_DIGITAL_ITEMS_REQUEST = "setDigitalItem";
const SET_PHYSICAL_ITEMS_REQUEST = "setPhysicalItem";
const SET_REQUEST_OFFER_TYPE = "setRequestOfferType";
const SET_COMBINED_ITEMS_REQUEST = "setCombinedItems";
const SET_CAMPAIGN_OPTION = "setCampaignOption";

// Namespace
const NAMESPACE = "requestOffer";

// Cache
const CACHE_NAME = "requestOfferCache";

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`;
};

export default {
  action: {
    CLEAR_REQUEST,
    SINGLE_REQUEST_OFFER,
    MULTIPLE_REQUEST_OFFER,
    CHANGE_MODAL_REQUEST_TYPE,
    EDIT_DIGITAL_FROM_REQUEST_OFFER,
    SELECT_CAMPAIGN_OPTION,
  },
  mutation: {
    SET_CLEAR_REQUEST_OFFER,
    SET_DIGITAL_ITEMS_REQUEST,
    SET_PHYSICAL_ITEMS_REQUEST,
    SET_REQUEST_OFFER_TYPE,
    SET_COMBINED_ITEMS_REQUEST,
    SET_CAMPAIGN_OPTION,
  },
  namespace: NAMESPACE,
  cacheName: CACHE_NAME,
  withNamespace,
};
