import { ActionContext } from "vuex";
import constants from "./constants";
import {
  ItemTypes,
  PhysicalTypes,
  ShortlistDigitalOptions,
  ShortlistPhysicalItem,
  ShortlistPhysicalOptions,
} from "@/store/modules/shortlist/types";

const {
  CLEAR_REQUEST,
  SINGLE_REQUEST_OFFER,
  MULTIPLE_REQUEST_OFFER,
  CHANGE_MODAL_REQUEST_TYPE,
  EDIT_DIGITAL_FROM_REQUEST_OFFER,
  SELECT_CAMPAIGN_OPTION,
} = constants.action;
const {
  SET_CLEAR_REQUEST_OFFER,
  SET_DIGITAL_ITEMS_REQUEST,
  SET_PHYSICAL_ITEMS_REQUEST,
  SET_REQUEST_OFFER_TYPE,
  SET_COMBINED_ITEMS_REQUEST,
  SET_CAMPAIGN_OPTION,
} = constants.mutation;

export default {
  [CLEAR_REQUEST]({ commit }: ActionContext<any, any>) {
    commit(SET_CLEAR_REQUEST_OFFER);
  },
  [SINGLE_REQUEST_OFFER](
    { commit, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_CLEAR_REQUEST_OFFER);
    switch (payload.type) {
      case ItemTypes.DIGITAL: {
        const digitalPayload = { buildings: [] as any };
        const building =
          rootState.project?.project?.buildings?.find(
            (building: any) => building.id === payload.building_id
          ) || null;
        if (building) {
          const foundOption =
            building.packs.find((pack: any) => pack.id === payload.item_id) ||
            null;
          if (foundOption) {
            const option: ShortlistDigitalOptions = {
              pack_id: foundOption.id,
              duration: foundOption.duration,
              daily_broadcasts: foundOption.daily_broadcasts,
              hourly_broadcasts: foundOption.hourly_broadcasts,
              name: foundOption.name,
              price: foundOption.price,
              type: foundOption.type,
              items: building?.digitalItems || [],
              unique_key: foundOption.unique_key,
            };
            digitalPayload.buildings.push({
              id: building.id,
              code: building.code,
              building_name: building.name,
              options: [option],
              itemsCampaignOptions: [
                {
                  packId: option.pack_id,
                  startDate: undefined,
                  endDate: undefined,
                  checkbox: undefined,
                },
              ],
            });
          }
        }
        commit(SET_DIGITAL_ITEMS_REQUEST, digitalPayload);
        break;
      }
      case ItemTypes.PHYSICAL: {
        const physicalPayload = { buildings: [] as any };
        const building =
          rootState.project?.project?.buildings?.find(
            (building: any) => building.id === payload.building_id
          ) || null;
        if (building) {
          const identifiers = payload.item_id.split("_"); // "areaId:1_type:tv"
          const areaId: string = identifiers[0].split(":")[1];
          const typeOfItem: string = identifiers[1].split(":")[1];
          // get area selected of building
          const foundOptions =
            building?.physicalItems?.filter(
              (p: any) =>
                p.physicalItem.area_id === Number(areaId) &&
                p.physicalItem.type === typeOfItem
            ) || [];
          if (foundOptions.length > 0) {
            const items: ShortlistPhysicalItem[] = [];
            for (const fOption of foundOptions) {
              items.push(fOption.physicalItem);
            }

            const valueOfType: PhysicalTypes =
              Object.values(PhysicalTypes).find(
                (v: string) => v === typeOfItem
              ) || PhysicalTypes.UNKNOWN;
            const option: ShortlistPhysicalOptions = {
              area: foundOptions[0].area,
              unique_key: foundOptions[0].unique_key,
              type: valueOfType,
              items,
            };
            physicalPayload.buildings.push({
              id: building.id,
              code: building.code,
              building_name: building.name,
              options: [option],
              itemsCampaignOptions: [
                {
                  type: valueOfType as PhysicalTypes,
                  startDate: undefined,
                  endDate: undefined,
                  checkbox: undefined,
                },
              ],
            });
          }
        }
        commit(SET_PHYSICAL_ITEMS_REQUEST, physicalPayload);
        break;
      }
      default:
        break;
    }
  },
  [MULTIPLE_REQUEST_OFFER]({ commit, rootState }: ActionContext<any, any>) {
    commit(SET_COMBINED_ITEMS_REQUEST, rootState.shortlist);
  },
  [CHANGE_MODAL_REQUEST_TYPE](
    { commit }: ActionContext<any, any>,
    payload: string
  ) {
    commit(SET_REQUEST_OFFER_TYPE, payload);
  },
  [EDIT_DIGITAL_FROM_REQUEST_OFFER](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      oldOption,
      buildingId,
      newType,
    }: { oldOption: any; buildingId: number; newType: string }
  ) {
    const building =
      rootState.project?.project?.buildings?.find(
        (b: any) => b.id === buildingId
      ) || null;

    if (!building) return;
    let newOption =
      building.packs.find(
        (p: any) => p.duration === oldOption.duration && p.type === newType
      ) || null;

    const itemsCampaignOptions = rootState.requestOffer.digital.buildings.find(
      (stateBuilding: any) => stateBuilding.id === buildingId
    ).itemsCampaignOptions;
    itemsCampaignOptions[0].packId = newOption.id;
    if (!newOption) return;
    newOption = {
      daily_broadcasts: newOption.daily_broadcasts,
      duration: newOption.duration,
      hourly_broadcasts: newOption.hourly_broadcasts,
      name: newOption.name,
      pack_id: newOption.id,
      price: newOption.price,
      type: newOption.type,
      unique_key: newOption.id,
      items: building?.digitalItems || [],
    };
    const digitalPayload = {
      buildings: [
        {
          id: building.id,
          code: building.code,
          building_name: building.name,
          options: [newOption],
          itemsCampaignOptions: itemsCampaignOptions,
        },
      ],
    };
    commit(SET_DIGITAL_ITEMS_REQUEST, digitalPayload);
  },
  [SELECT_CAMPAIGN_OPTION](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      buildingId,
      optionIdx,
      startDate,
      endDate,
      checkbox,
      itemType,
    }: {
      buildingId: number;
      optionIdx: string | number;
      startDate?: Date;
      endDate?: Date;
      checkbox?: boolean;
      itemType: ItemTypes;
    }
  ) {
    commit(SET_CAMPAIGN_OPTION, {
      buildingId,
      optionIdx,
      startDate,
      endDate,
      checkbox,
      itemType,
    });

    if (state.requestOfferType !== "single") {
      if (itemType === ItemTypes.DIGITAL) {
        try {
          const cache: any =
            JSON.parse(localStorage.getItem("shortlistCache") as string) || {};
          const activeProject = rootState.project.activeProject;
          if (activeProject in cache) {
            const foundB =
              cache[activeProject]?.digital?.buildings?.find(
                (b: any) => b.id === buildingId
              ) || null;
            if (foundB) {
              const itemsCampaignOptions =
                foundB?.itemsCampaignOptions?.find(
                  (o: any) => o.packId === optionIdx
                ) || null;
              if (itemsCampaignOptions) {
                startDate ? (itemsCampaignOptions.startDate = startDate) : "";
                endDate ? (itemsCampaignOptions.endDate = endDate) : "";
                checkbox !== undefined
                  ? (itemsCampaignOptions.checkbox = checkbox)
                  : "";
                localStorage.setItem("shortlistCache", JSON.stringify(cache));
              }
            }
          }
        } catch (e) {
          console.log(
            "Unable to edit digital item from Shortlist Cache Object",
            e
          );
        }
      } else if (itemType === ItemTypes.PHYSICAL) {
        try {
          const cache: any =
            JSON.parse(localStorage.getItem("shortlistCache") as string) || {};
          const activeProject = rootState.project.activeProject;
          if (activeProject in cache) {
            const foundB =
              cache[activeProject]?.physical?.buildings?.find(
                (b: any) => b.id === buildingId
              ) || null;
            if (foundB) {
              const itemsCampaignOptions =
                foundB?.itemsCampaignOptions?.find(
                  (o: any) => o.type === optionIdx
                ) || null;
              if (itemsCampaignOptions) {
                startDate ? (itemsCampaignOptions.startDate = startDate) : "";
                endDate ? (itemsCampaignOptions.endDate = endDate) : "";
                checkbox !== undefined
                  ? (itemsCampaignOptions.checkbox = checkbox)
                  : "";
                localStorage.setItem("shortlistCache", JSON.stringify(cache));
              }
            }
          }
        } catch (e) {
          console.log(
            "Unable to edit digital item from Shortlist Cache Object",
            e
          );
        }
      }
    }
  },
};
