import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import {initMiddleware} from "./midleware";
import PortfolioRoutes from "@/router/portfolio";
import ProjectRoutes from "@/router/project";
import OfferRoutes from "@/router/offerRoutes";
import BaseRoutes from "@/router/base";
import config from "../config";
import ProjectStaticPageRoutes from "@/router/projectStaticPages";

Vue.use(VueRouter);

const buildRoutes = (cfg: any): Array<RouteConfig> => {
    let adminUrl = '';
    switch(config.RUNTIME_ENV) {
        case 'staging':
            adminUrl = `${config.CLIENT}.admin.staging.brightspaces.tech`;
            break;
        case 'production':
            adminUrl = `${config.CLIENT}.admin.brightspaces.tech`
            break;
        default:
            adminUrl = `${config.CLIENT}.admin.dev.brightspaces.tech`
            break;
    }
    return [
        ...PortfolioRoutes(cfg),
        {
            path: '/login',
            name: 'RedirectionView',
            component: () => import(/* webpackChunkName: "redirect-view" */ "../views/Redirect.vue"),
            meta: {
                location: adminUrl
            }
        },
        ...ProjectRoutes(cfg),
        ...ProjectStaticPageRoutes(cfg),
        // ...OfferRoutes(cfg),
        ...BaseRoutes(cfg),
        //Should always be last
        {
            path: '*',
            name: 'NotFound',
            component: () => import(/* webpackChunkName: "not-found-page" */ '../views/base/NotFoundPage.vue')
        }
    ];
}

export const createRouter = function (cfg: any) {
    const routes = buildRoutes(cfg);
    const router = new VueRouter({
        mode: "history",
        routes,
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return;
            }
            return { x: 0, y: 0 }
        }
    });

    initMiddleware(router);

    return router;
}