import {RouteConfig} from 'vue-router';

const PortfolioRoutes = (cfg: any): Array<RouteConfig> => {
    const routes = {
        path: '/',
        component: () => import(/* webpackChunkName: "landing-template" */ "../templates/LandingTemplate.vue"),
        children: [
            {
                path: "",
                name: "Home",
                component: () => import(/* webpackChunkName: "landing-page" */ "../views/portfolio/PageLanding.vue"),
                meta: {
                    page: {
                        meta: {
                            ...cfg.landing.meta,
                            baseTitle: cfg.general.baseTitle
                        },
                        sections: cfg.landing.sections,
                    }
                }
            }
        ]
    };
    if (cfg.general.portfolioPageDisabled) {
        return [];
    }
    return [
        routes
    ]
};

export default PortfolioRoutes;