import { ActionContext } from "vuex";
import constants from "./constants";
import {
  FiltersResults,
  ProjectState,
  RequestOfferRegistry,
  ShortlistRegistry,
  ShortlistRegistryPayload,
} from "./types";
import RequestOffer from "@/store/modules/requestOffer/RequestOffer";
import { RequestOfferState } from "@/store/modules/requestOffer/types";

const {
  LOAD_LANDLORD_DETAILS,
  LOAD_PROJECT_AVAILABILITY,
  CHANGE_LOADING_STATE,
  TOGGLE_INFO_BOX,
  TOGGLE_MEDIA_INFO_BOX,
  TOGGLE_EXPAND_GALLERY,
  TOGGLE_INFO_AREA,
  CHANGE_ACTIVE_PROJECT,
  CHANGE_FILTER_STATE,
  DOWNLOAD_FILE,
  RESET_FILTERS,
  RESET_MULTIPLE_SELECT_FILTER,
  ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST,
  CLEAR_SHORTLIST,
  REPLACE_SHORTLIST,
  EDIT_SHORTLIST_DIGITAL,
  ADD_TO_REQUEST_OFFER,
  REMOVE_FROM_REQUEST_OFFER,
  COUNT_RESULTS,
  CLEAR_REQUEST_OFFER_LIST,
} = constants.action;
const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_INFO_BOX_STATE,
  SET_MEDIA_INFO_BOX_STATE,
  SET_EXPAND_GALLERY_STATE,
  SET_INFO_AREA_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_BUILDING,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_SHORTLIST,
  SET_REQUEST_OFFER,
  SET_COUNT_RESULTS,
  SET_EMPTY_REQUEST_OFFER_LIST,
} = constants.mutation;

export default {
  [LOAD_LANDLORD_DETAILS]({
    commit,
    dispatch,
    rootGetters,
    rootState,
  }: ActionContext<any, any>) {
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    commit(SET_LOADING_STATE, true);
    changePageLoading(true);
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.details())
        .then((res: any) => {
          commit(SET_LANDLORD_DETAILS, res.data.result);
          commit(SET_LOADING_STATE, false);
          resolve({
            result: res,
            error: null,
          });
          if (res.data.result.projects.length) {
            const defaultProject = { ...res.data.result.projects[0] };
            const currentRoute = rootGetters.router.app.$route;
            if (
              (["SpecificProject", "SpecificSpace"].includes(
                currentRoute.name
              ) ||
                currentRoute.meta.isProjectStaticPage) &&
              currentRoute.params.projectId
            ) {
              const foundBuilding = res.data.result.buildings.find(
                (item: any) => item.code === currentRoute.params.projectId
              );
              const foundProject = res.data.result.projects.find(
                (item: any) => item.id === foundBuilding.project_id
              );
              if (foundProject) {
                commit(SET_ACTIVE_BUILDING, foundProject.id);
                commit(SET_ACTIVE_PROJECT, foundProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, foundProject.id);
              } else {
                commit(SET_ACTIVE_PROJECT, defaultProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id);
                rootGetters.router.push({ name: "NotFound", params: {} });
              }
            } else {
              commit(SET_ACTIVE_PROJECT, defaultProject.slug);
              dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id);
            }
          } else {
            changePageLoading(false);
          }
        })
        .catch((e: any) => {
          commit(SET_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  async [LOAD_PROJECT_AVAILABILITY](
    { commit, dispatch, rootGetters, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_PROJECT_LOADING_STATE, true);
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(payload))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [TOGGLE_INFO_BOX]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_INFO_BOX_STATE, {
      open: payload.open,
    });
  },
  [TOGGLE_MEDIA_INFO_BOX]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_MEDIA_INFO_BOX_STATE, {
      open: payload.open,
      data: payload.data,
    });
  },
  [TOGGLE_EXPAND_GALLERY]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_EXPAND_GALLERY_STATE, {
      open: payload.open,
    });
  },
  [TOGGLE_INFO_AREA]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_INFO_AREA_STATE, {
      open: payload.open,
    });
  },
  [CHANGE_LOADING_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_LOADING_STATE, payload);
  },
  [CHANGE_ACTIVE_PROJECT](
    { commit, dispatch, state, rootGetters }: ActionContext<any, any>,
    payload: string
  ) {
    commit(SET_ACTIVE_PROJECT, payload);
    commit(SET_PROJECT_LOADING_STATE, true);
    const projectData = state.projects.find(
      (project: any) => project.slug === payload
    );
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(projectData.id))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          const requestOffer = rootGetters.constants.requestOffer;
          dispatch(
            requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
            null,
            { root: true }
          );
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [CHANGE_FILTER_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_FILTER_STATE, payload);
  },
  [DOWNLOAD_FILE](
    { commit, state, rootState, rootGetters }: ActionContext<any, any>,
    payload: any
  ) {
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .POST(rootGetters.urlsHelper.landlord.downloadFile(), payload)
        .then((res: any) => {
          resolve({
            result: res.data.result,
            error: null,
          });
        })
        .catch((e: any) => {
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [RESET_MULTIPLE_SELECT_FILTER](
    { commit, state }: ActionContext<any, any>,
    payload: any
  ) {
    if (payload.parentKey === "digital") {
      commit(SET_EMPTY_FILTERS, {
        digital: {
          areas: [],
        },
      });
    }
    if (payload.parentKey === "physical") {
      commit(SET_EMPTY_FILTERS, {
        physical: {
          areas: [],
        },
      });
    }
  },
  [RESET_FILTERS]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      digital: {
        areas: [],
        typeOfPackage: "all",
        advertisingTiming: "all",
        showAllGroupOptions: false,
      },
      physical: {
        areas: [],
        devices: [],
      },
    });
  },
  [ADD_TO_SHORTLIST](
    { commit, state }: ActionContext<any, any>,
    payload: ShortlistRegistryPayload
  ) {
    const newPayload: ShortlistRegistry = {
      buildings_ids: [
        ...new Set([
          ...state.shortlistRegistry.buildings_ids,
          payload.building_id,
        ]),
      ],
      items_ids: [
        ...new Set([...state.shortlistRegistry.items_ids, payload.item_id]),
      ],
      type: payload.type,
    };
    if (!state.shortlistRegistry.physical_item_types) {
      state.shortlistRegistry.physical_item_types = [];
    }
    if (Object.hasOwn(payload, "physical_item_type")) {
      newPayload.physical_item_types = [
        ...new Set([
          ...state.shortlistRegistry.physical_item_types,
          payload.physical_item_type,
        ]),
      ];
      if (state.shortlistRegistry.typesAreas) {
        newPayload.typesAreas = [
          ...state.shortlistRegistry.typesAreas,
          `${payload.physical_item_type}_${payload.item_id}`,
        ];
      } else {
        newPayload.typesAreas = [
          `${payload.physical_item_type}_${payload.item_id}`,
        ];
      }
    }
    commit(SET_SHORTLIST, newPayload);
  },
  [REMOVE_FROM_SHORTLIST](
    { commit, state }: ActionContext<any, any>,
    payload: ShortlistRegistryPayload
  ) {
    const newItemIds = state.shortlistRegistry.items_ids.filter(
      (item_id: number) => item_id !== payload.item_id
    );
    const newPayload: ShortlistRegistry = {
      buildings_ids: state.shortlistRegistry.buildings_ids,
      items_ids: newItemIds,
    };
    if (state.shortlistRegistry.type === "physical") {
      newPayload.typesAreas = state.shortlistRegistry.typesAreas.filter(
        (typeArea: string) =>
          typeArea !== `${payload.physical_item_type}_${payload.item_id}`
      );
      const existsItemType = newPayload.typesAreas?.find((typeArea) =>
        typeArea.includes(`${payload.physical_item_type}`)
      );
      newPayload.physical_item_types = existsItemType
        ? state.shortlistRegistry.physical_item_types
        : state.shortlistRegistry.physical_item_types.filter(
            (physical_item_type: string) =>
              physical_item_type !== payload.physical_item_type
          );
    }
    newPayload.type =
      newItemIds.length > 0 ||
      (newPayload.typesAreas && newPayload.typesAreas?.length > 0)
        ? state.shortlistRegistry.type
        : undefined;
    commit(SET_SHORTLIST, newPayload);
  },
  [CLEAR_SHORTLIST]({ commit }: ActionContext<any, any>) {
    commit(SET_SHORTLIST, {
      buildings_ids: [],
      items_ids: [],
      physical_item_types: [],
      typesAreas: [],
    });
  },
  [REPLACE_SHORTLIST](
    { commit }: ActionContext<any, any>,
    payload: ShortlistRegistryPayload
  ) {
    commit(SET_SHORTLIST, {
      buildings_ids: [payload.building_id],
      items_ids: [payload.item_id],
      type: payload.type,
      physical_item_types: [payload.physical_item_type],
      typesAreas: [`${payload.physical_item_type}_${payload.item_id}`],
    });
  },
  [EDIT_SHORTLIST_DIGITAL](
    { commit, state }: ActionContext<any, any>,
    payload: ShortlistRegistry
  ) {
    const newPayload: ShortlistRegistry = {
      buildings_ids: state.shortlistRegistry.buildings_ids,
      items_ids: payload.items_ids,
      type: state.shortlistRegistry.type,
    };
    commit(SET_SHORTLIST, newPayload);
  },
  [ADD_TO_REQUEST_OFFER]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_REQUEST_OFFER, payload);
  },
  [REMOVE_FROM_REQUEST_OFFER](
    { commit, state }: ActionContext<any, any>,
    payload: any
  ) {
    const newPayload: RequestOfferRegistry = {
      buildings_ids: state.requestOfferItems.buildings_ids,
      items_ids:
        state.requestOfferItems.type === "digital"
          ? state.requestOfferItems.items_ids.filter(
              (item: number) => item !== payload.item_id
            )
          : state.requestOfferItems.items_ids,
      type: state.requestOfferItems.type,
    };
    if (state.requestOfferItems.type === "physical") {
      newPayload.physical_item_types = state.requestOfferItems.physical_item_types.filter(
        (type: string) => type !== payload.physical_item_type
      );
      newPayload.typesAreas = state.requestOfferItems.typesAreas.filter(
        (typeArea: string) => !typeArea.includes(payload.physical_item_type)
      );
    }

    commit(SET_REQUEST_OFFER, newPayload);
  },
  [COUNT_RESULTS](
    { commit }: ActionContext<any, any>,
    payload: FiltersResults
  ) {
    commit(SET_COUNT_RESULTS, payload);
  },
  [CLEAR_REQUEST_OFFER_LIST](
    { commit }: ActionContext<any, any>,
    payload: { reset: boolean }
  ) {
    commit(SET_EMPTY_REQUEST_OFFER_LIST, payload);
  },
};
