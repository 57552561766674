// export type ShortlistState = {
//   digital: [];
//   physical: [];
// };

export enum ItemTypes {
  DIGITAL = "digital",
  PHYSICAL = "physical",
}

export enum PackTypes {
  BRONZE = "bronze",
  SILVER = "silver",
  GOLD = "gold",
  PLATINUM = "platinum",
}

export enum PhysicalTypes {
  UNKNOWN = "unknown",
  PANELS = "panels",
  ESCALATORS = "escalators",
  MESHES = "meshes",
  COLUMNS = "columns",
  HANGERS = "hangers",
}

export type ShortlistBuilding = {
  id: number;
  code: string;
  // name: string,
  // is_available: boolean,
  // project_id: number,
  options: (ShortlistDigitalOptions | ShortlistPhysicalOptions)[];
  itemsCampaignOptions: (PhysicalItemTypeDate | DigitalItemDate)[];
};

export type ShortlistDigitalOptions = {
  pack_id: number;
  duration: number;
  daily_broadcasts: number;
  hourly_broadcasts: number;
  name: string;
  price: number;
  type: PackTypes;
  unique_key: number;
  items?: ShortlistDigitalItem[];
  startDate?: Date;
  endDate?: Date;
};

export type ShortlistDigitalItem = {
  id: number;
  area_id: number;
  floor_id: number;
  code: string;
  daily_views: number;
  description: string;
  hourly_views: number;
  name: string;
  specs: string;
  startDate?: Date;
  endDate?: Date;
};

export type ShortlistPhysicalOptions = {
  area: ShortlistArea;
  type: PhysicalTypes;
  items?: ShortlistPhysicalItem[];
  unique_key: string;
  startDate?: Date;
  endDate?: Date;
};

export type PhysicalItemTypeDate = {
  type: PhysicalTypes;
  startDate?: Date;
  endDate?: Date;
  checkbox?: boolean;
};

export type DigitalItemDate = {
  packId: number;
  startDate?: Date;
  endDate?: Date;
  checkbox?: boolean;
};

export type ShortlistPhysicalItem = {
  id: number;
  floor_id: number;
  name: string;
  description: string;
  specs: string;
  type: string;
};

export type ShortlistArea = {
  id: number;
  name: string;
};

export type ShortlistState = {
  digital: {
    buildings: ShortlistBuilding[];
  };
  physical: {
    buildings: ShortlistBuilding[];
  };
  total: number;
};
