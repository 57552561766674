import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { RequestOfferState } from "./types";

const state: RequestOfferState = {
  digital: [],
  physical: [],
  requestOfferType: "",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
