// Actions
const CHANGE_LOADING_STATE = "changeLoadingState";
const CHANGE_PROJECT_LOADING_STATE = "changeProjectLoadingState";
const CHANGE_ACTIVE_PROJECT = "changeActiveProject";
const LOAD_LANDLORD_DETAILS = "loadLandlordDetails";
const TOGGLE_INFO_BOX = "toggleInfoBox";
const TOGGLE_MEDIA_INFO_BOX = "toggleMediaInfoBox";
const TOGGLE_EXPAND_GALLERY = "toggleExpandGallery";
const TOGGLE_INFO_AREA = "toggleInfoArea";
const LOAD_PROJECT_AVAILABILITY = "loadProjectAvailability";
const CHANGE_FILTER_STATE = "changeFilterState";
const DOWNLOAD_FILE = "downloadFile";
const RESET_FILTERS = "resetFilters";
const RESET_MULTIPLE_SELECT_FILTER = "resetMultipleSelectFilter";
const ADD_TO_SHORTLIST = "addToShortlist";
const REMOVE_FROM_SHORTLIST = "removeFromShortlist";
const CLEAR_SHORTLIST = "clearShortlist";
const REPLACE_SHORTLIST = "replaceShortlist";
const EDIT_SHORTLIST_DIGITAL = "editShortlistDigital";
const ADD_TO_REQUEST_OFFER = "addToRequestOffer";
const REMOVE_FROM_REQUEST_OFFER = "removeFromRequestOffer";
const COUNT_RESULTS = "countResults";
const CLEAR_REQUEST_OFFER_LIST = "clearRequestOfferList";

// Mutations
const SET_LANDLORD_DETAILS = "setLandlordDetails";
const SET_LOADING_STATE = "setLoadingState";
const SET_PROJECT_LOADING_STATE = "setProjectLoadingState";
const SET_INFO_BOX_STATE = "setInfoBoxState";
const SET_MEDIA_INFO_BOX_STATE = "setMediaInfoBoxState";
const SET_EXPAND_GALLERY_STATE = "setExpandGalleryState";
const SET_INFO_AREA_STATE = "setInfoAreaState";
const SET_PROJECT_AVAILABILITY = "setProjectAvailability";
const SET_ACTIVE_BUILDING = "setActiveBuilding";
const SET_ACTIVE_PROJECT = "setActiveProject";
const SET_FILTER_STATE = "setFilterState";
const SET_EMPTY_FILTERS = "setEmptyFilters";
const SET_SHORTLIST = "setShortlist";
const SET_REQUEST_OFFER = "setRequestOffer";
const SET_COUNT_RESULTS = "setCountResults";
const SET_EMPTY_REQUEST_OFFER_LIST = "setEmptyRequestOfferList";

// Getters
const GET_SHORTLIST_ITEMS = "shortlistItems";
// Namespace
const NAMESPACE = "project";

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`;
};

export default {
  action: {
    CHANGE_LOADING_STATE,
    CHANGE_PROJECT_LOADING_STATE,
    CHANGE_ACTIVE_PROJECT,
    LOAD_LANDLORD_DETAILS,
    TOGGLE_INFO_BOX,
    TOGGLE_MEDIA_INFO_BOX,
    TOGGLE_EXPAND_GALLERY,
    TOGGLE_INFO_AREA,
    LOAD_PROJECT_AVAILABILITY,
    CHANGE_FILTER_STATE,
    DOWNLOAD_FILE,
    RESET_FILTERS,
    RESET_MULTIPLE_SELECT_FILTER,
    ADD_TO_SHORTLIST,
    REMOVE_FROM_SHORTLIST,
    CLEAR_SHORTLIST,
    REPLACE_SHORTLIST,
    EDIT_SHORTLIST_DIGITAL,
    ADD_TO_REQUEST_OFFER,
    REMOVE_FROM_REQUEST_OFFER,
    COUNT_RESULTS,
    CLEAR_REQUEST_OFFER_LIST,
  },
  mutation: {
    SET_LANDLORD_DETAILS,
    SET_LOADING_STATE,
    SET_ACTIVE_BUILDING,
    SET_ACTIVE_PROJECT,
    SET_INFO_BOX_STATE,
    SET_MEDIA_INFO_BOX_STATE,
    SET_EXPAND_GALLERY_STATE,
    SET_INFO_AREA_STATE,
    SET_PROJECT_LOADING_STATE,
    SET_PROJECT_AVAILABILITY,
    SET_FILTER_STATE,
    SET_EMPTY_FILTERS,
    SET_SHORTLIST,
    SET_REQUEST_OFFER,
    SET_COUNT_RESULTS,
    SET_EMPTY_REQUEST_OFFER_LIST,
  },
  getter: {
    GET_SHORTLIST_ITEMS,
  },
  namespace: NAMESPACE,
  withNamespace,
};
