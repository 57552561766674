import { ActionContext } from "vuex";
import constants from "./constants";
import {
  ItemTypes,
  PhysicalTypes,
  ShortlistDigitalOptions,
  ShortlistPhysicalItem,
  ShortlistPhysicalOptions,
} from "@/store/modules/shortlist/types";

const {
  LOAD_ITEMS,
  ADD_DIGITAL_TO_SHORTLIST,
  ADD_PHYSICAL_TO_SHORTLIST,
  EDIT_DIGITAL_FROM_SHORTLIST,
  REMOVE_DIGITAL_FROM_SHORTLIST,
  REMOVE_PHYSICAL_FROM_SHORTLIST,
  REMOVE_PHYSICAL_BY_TYPE_FROM_SHORTLIST,
  CLEAR_SHORTLIST,
} = constants.action;
const {
  SET_STORE_VALUES,
  SET_BUILDING,
  SET_NEW_ITEM,
  SET_EDITED_ITEM,
  SET_ITEMS,
  SET_ITEM_DATES,
  SET_EMPTY,
  SET_TOTAL,
} = constants.mutation;

export default {
  [LOAD_ITEMS]({ commit }: ActionContext<any, any>, localStorageItems: any) {
    commit(SET_STORE_VALUES, localStorageItems);
  },

  [ADD_DIGITAL_TO_SHORTLIST](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      building_id,
      item_id,
    }: {
      building_id: number;
      item_id: number;
    }
  ) {
    const existingBuildings = [...state.digital.buildings];
    const fBuilding =
      existingBuildings?.find((b: any) => b.id === building_id) || null;
    if (!fBuilding) {
      // we don't have the building in shortlist store
      // so we have to add the building first
      const b =
        rootState.project?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      commit(SET_BUILDING, {
        building: {
          id: b.id,
          code: b.code,
          building_name: b.name,
          options: [],
          itemsCampaignOptions: [],
        },
        type: ItemTypes.DIGITAL,
      });

      // we have to get the building metadata in order to get pack and digital items information
      const building =
        rootState.project?.project?.buildings?.find(
          (b: any) => b.id === building_id
        ) || null;
      // then we have to add the option selected by the user into that building options array
      const fOption =
        building?.packs?.find((p: any) => p.id === item_id) || null;
      if (fOption) {
        const option: ShortlistDigitalOptions = {
          pack_id: fOption.id,
          duration: fOption.duration,
          daily_broadcasts: fOption.daily_broadcasts,
          hourly_broadcasts: fOption.hourly_broadcasts,
          name: fOption.name,
          price: fOption.price,
          type: fOption.type,
          unique_key: fOption.id,
          items: building?.digitalItems || [],
        };
        commit(SET_NEW_ITEM, {
          building_id,
          type: ItemTypes.DIGITAL,
          option,
        });
        commit(SET_TOTAL, { type: "increment" });

        // Add to cache for current active project
        try {
          const cache: any =
            JSON.parse(localStorage.getItem(constants.cacheName) as string) ||
            {};
          const activeProject = rootState.project.activeProject;
          if (!(activeProject in cache)) {
            cache[activeProject] = {};
            cache[activeProject].total = 0;
          }
          if (activeProject in cache && !("digital" in cache[activeProject])) {
            cache[activeProject].digital = {};
            cache[activeProject].digital.buildings = [];
            cache[activeProject].digital.buildings.push({
              id: b.id,
              code: b.code,
              building_name: b.name,
              options: [],
              itemsCampaignOptions: [],
            });
          }
          const fBuilding =
            cache[activeProject]?.digital?.buildings?.find(
              (b: any) => b.id === building_id
            ) || null;
          if (fBuilding) {
            fBuilding.options.push(option);
            const index = fBuilding.itemsCampaignOptions.findIndex(
              (object: any) => object.packId === option.pack_id
            );
            if (index === -1) {
              fBuilding.itemsCampaignOptions.push({
                packId: option.pack_id,
                startDate: undefined,
                endDate: undefined,
                checkbox: undefined,
              });
            }
          }
          cache[activeProject].total += 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        } catch (e) {
          console.log(
            "Unable to save digital item to Shortlist Cache Object",
            e
          );
        }
      }
    } else {
      // it means that we have the building with at least one item inside, so we have to add new item into the options array
      const building =
        rootState.project?.project?.buildings?.find(
          (b: any) => b.id === building_id
        ) || null;
      const fOption =
        building?.packs?.find((p: any) => p.id === item_id) || null;
      if (fOption) {
        if (fBuilding?.options?.find((o: any) => o.pack_id === fOption.id)) {
          // it means that we have the item already in options array, so we skip the insert.
          return;
        }
        // we insert the item in options array
        const option: ShortlistDigitalOptions = {
          pack_id: fOption.id,
          duration: fOption.duration,
          daily_broadcasts: fOption.daily_broadcasts,
          hourly_broadcasts: fOption.hourly_broadcasts,
          name: fOption.name,
          price: fOption.price,
          type: fOption.type,
          unique_key: fOption.id,
          items: building?.digitalItems || [],
        };
        commit(SET_NEW_ITEM, {
          building_id,
          type: ItemTypes.DIGITAL,
          option,
        });
        commit(SET_TOTAL, { type: "increment" });

        // Add to cache for current active project
        try {
          const cache: any =
            JSON.parse(localStorage.getItem(constants.cacheName) as string) ||
            {};
          const activeProject = rootState.project.activeProject;
          const fBuilding =
            cache[activeProject]?.digital?.buildings?.find(
              (b: any) => b.id === building_id
            ) || null;
          if (fBuilding) {
            fBuilding.options.push(option);
            const index = fBuilding.itemsCampaignOptions.findIndex(
              (object: any) => object.packId === option.pack_id
            );
            if (index === -1) {
              fBuilding.itemsCampaignOptions.push({
                packId: option.pack_id,
                startDate: undefined,
                endDate: undefined,
                checkbox: undefined,
              });
            }
          }
          cache[activeProject].total += 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        } catch (e) {
          console.log(
            "Unable to save digital item to Shortlist Cache Object",
            e
          );
        }
      }
    }
  },

  [ADD_PHYSICAL_TO_SHORTLIST](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      building_id,
      item_id,
    }: {
      building_id: number;
      item_id: string;
    }
  ) {
    const existingBuildings = [...state.physical.buildings];
    const fBuilding =
      existingBuildings?.find((b: any) => b.id === building_id) || null;
    if (!fBuilding) {
      // we don't have the building in shortlist store
      // so we have to add the building first
      const b =
        rootState.project?.buildings?.find((b: any) => b.id === building_id) ||
        null;
      commit(SET_BUILDING, {
        building: {
          id: b.id,
          code: b.code,
          building_name: b.name,
          options: [],
          itemsCampaignOptions: [],
        },
        type: ItemTypes.PHYSICAL,
      });

      // we have to get the building metadata in order to get area, type and physical items information
      const building =
        rootState.project?.project?.buildings?.find(
          (b: any) => b.id === building_id
        ) || null;
      // then we have to add the option selected by the user into that building options array

      const identifiers = item_id.split("_"); // "areaId:1_type:tv"
      const areaId: string = identifiers[0].split(":")[1];
      const typeOfItem: string = identifiers[1].split(":")[1];

      const fOptions =
        building?.physicalItems?.filter(
          (p: any) =>
            p.physicalItem.area_id === Number(areaId) &&
            p.physicalItem.type === typeOfItem
        ) || [];
      if (fOptions.length > 0) {
        const items: ShortlistPhysicalItem[] = [];
        for (const fOption of fOptions) {
          items.push(fOption.physicalItem);
        }

        const valueOfType: PhysicalTypes =
          Object.values(PhysicalTypes).find((v: string) => v === typeOfItem) ||
          PhysicalTypes.UNKNOWN;
        const option: ShortlistPhysicalOptions = {
          area: fOptions[0].area,
          type: valueOfType,
          items,
          unique_key: item_id,
        };
        commit(SET_NEW_ITEM, {
          building_id,
          type: ItemTypes.PHYSICAL,
          option,
        });
        commit(SET_TOTAL, { type: "increment" });

        // Add to cache for current active project
        try {
          const cache: any =
            JSON.parse(localStorage.getItem(constants.cacheName) as string) ||
            {};
          const activeProject = rootState.project.activeProject;
          if (!(activeProject in cache)) {
            cache[activeProject] = {};
            cache[activeProject].total = 0;
          }
          if (activeProject in cache && !("physical" in cache[activeProject])) {
            cache[activeProject].physical = {};
            cache[activeProject].physical.buildings = [];
            cache[activeProject].physical.buildings.push({
              id: b.id,
              code: b.code,
              building_name: b.name,
              options: [],
              itemsCampaignOptions: [],
            });
          }
          const fBuilding =
            cache[activeProject]?.physical?.buildings?.find(
              (b: any) => b.id === building_id
            ) || null;
          if (fBuilding) {
            fBuilding.options.push(option);
            const index = fBuilding.itemsCampaignOptions.findIndex(
              (object: any) => object.type === option.type
            );
            if (index === -1) {
              fBuilding.itemsCampaignOptions.push({
                type: option.type as PhysicalTypes,
                startDate: undefined,
                endDate: undefined,
                checkbox: undefined,
              });
            }
          }
          cache[activeProject].total += 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        } catch (e) {
          console.log(
            "Unable to save physical item to Shortlist Cache Object",
            e
          );
        }
      }
    } else {
      // it means that we have the building with at least one item inside, so we have to add new item into the options array
      const building =
        rootState.project?.project?.buildings?.find(
          (b: any) => b.id === building_id
        ) || null;

      const identifiers = item_id.split("_"); // "areaId:1_type:tv"
      const areaId: string = identifiers[0].split(":")[1];
      const typeOfItem: string = identifiers[1].split(":")[1];

      const fOptions =
        building?.physicalItems?.filter(
          (p: any) =>
            p.physicalItem.area_id === Number(areaId) &&
            p.physicalItem.type === typeOfItem
        ) || [];
      if (fOptions.length > 0) {
        const items: ShortlistPhysicalItem[] = [];
        for (const fOption of fOptions) {
          if (
            fBuilding?.options?.find((o: any) =>
              o.items.some((i: any) => i.id === fOption.physicalItem.id)
            )
          ) {
            // it means that we have the item already in options array, so we skip the insert.
            return;
          }
          items.push(fOption.physicalItem);
        }
        const valueOfType: PhysicalTypes =
          Object.values(PhysicalTypes).find((v: string) => v === typeOfItem) ||
          PhysicalTypes.UNKNOWN;
        const option: ShortlistPhysicalOptions = {
          area: fOptions[0].area,
          type: valueOfType,
          items,
          unique_key: item_id,
        };
        commit(SET_NEW_ITEM, {
          building_id,
          type: ItemTypes.PHYSICAL,
          option,
        });
        commit(SET_TOTAL, { type: "increment" });

        // Add to cache for current active project
        try {
          const cache: any =
            JSON.parse(localStorage.getItem(constants.cacheName) as string) ||
            {};
          const activeProject = rootState.project.activeProject;
          const fBuilding =
            cache[activeProject]?.physical?.buildings?.find(
              (b: any) => b.id === building_id
            ) || null;
          if (fBuilding) {
            fBuilding.options.push(option);
            const index = fBuilding.itemsCampaignOptions.findIndex(
              (object: any) => object.type === option.type
            );
            if (index === -1) {
              fBuilding.itemsCampaignOptions.push({
                type: option.type as PhysicalTypes,
                startDate: undefined,
                endDate: undefined,
                checkbox: undefined,
              });
            }
          }
          cache[activeProject].total += 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        } catch (e) {
          console.log(
            "Unable to save physical item to Shortlist Cache Object",
            e
          );
        }
      }
    }
  },

  [REMOVE_DIGITAL_FROM_SHORTLIST](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      building_id,
      item_id,
    }: {
      building_id: number;
      item_id: number;
    }
  ) {
    const existingBuildings = [...state.digital.buildings];
    const fBuilding =
      existingBuildings?.find((b: any) => b.id === building_id) || null;
    if (!fBuilding) return;
    const options =
      fBuilding?.options?.filter((o: any) => o.pack_id !== item_id) || [];
    commit(SET_ITEMS, {
      building_id,
      type: ItemTypes.DIGITAL,
      options,
    });
    commit(SET_TOTAL, { type: "decrement" });

    try {
      const cache: any =
        JSON.parse(localStorage.getItem(constants.cacheName) as string) || {};
      const activeProject = rootState.project.activeProject;
      if (activeProject in cache) {
        const foundB =
          cache[activeProject].digital.buildings.find(
            (b: any) => b.id === building_id
          ) || null;
        if (foundB) {
          foundB.options =
            foundB?.options?.filter((o: any) => o.pack_id !== item_id) || [];
          cache[activeProject].total -= 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        }
      }
    } catch (e) {
      console.log(
        "Unable to remove digital item from Shortlist Cache Object",
        e
      );
    }
  },

  [REMOVE_PHYSICAL_FROM_SHORTLIST](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      building_id,
      item_id,
    }: {
      building_id: number;
      item_id: string;
    }
  ) {
    const existingBuildings = [...state.physical.buildings];
    const fBuilding =
      existingBuildings?.find((b: any) => b.id === building_id) || null;
    if (!fBuilding) return;

    const identifiers = item_id.split("_"); // "areaId:1_type:tv"
    const areaId: string = identifiers[0].split(":")[1];
    const typeOfItem: string = identifiers[1].split(":")[1];

    const options =
      fBuilding?.options?.filter((o: any) => {
        return o.area.id !== Number(areaId) || o.type !== typeOfItem;
      }) || [];
    commit(SET_ITEMS, {
      building_id,
      type: ItemTypes.PHYSICAL,
      options,
    });
    if (!options.find((option: any) => option.type === typeOfItem)) {
      const itemsCampaignOptions =
        fBuilding?.itemsCampaignOptions?.filter((o: any) => {
          return o.type !== typeOfItem;
        }) || [];
      commit(SET_ITEM_DATES, {
        building_id,
        type: ItemTypes.PHYSICAL,
        itemsCampaignOptions,
      });
    }
    commit(SET_TOTAL, { type: "decrement" });

    try {
      const cache: any =
        JSON.parse(localStorage.getItem(constants.cacheName) as string) || {};
      const activeProject = rootState.project.activeProject;
      if (activeProject in cache) {
        const foundB =
          cache[activeProject].physical.buildings.find(
            (b: any) => b.id === building_id
          ) || null;
        if (foundB) {
          foundB.options =
            foundB?.options?.filter((o: any) => {
              return o.area.id !== Number(areaId) || o.type !== typeOfItem;
            }) || [];
          cache[activeProject].total -= 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        }
      }
    } catch (e) {
      console.log(
        "Unable to remove physical item from Shortlist Cache Object",
        e
      );
    }
  },
  [REMOVE_PHYSICAL_BY_TYPE_FROM_SHORTLIST](
    { commit, state, rootState }: ActionContext<any, any>,
    {
      building_id,
      item_type,
    }: {
      building_id: number;
      item_type: string;
    }
  ) {
    const existingBuildings = [...state.physical.buildings];
    const fBuilding =
      existingBuildings?.find((b: any) => b.id === building_id) || null;
    if (!fBuilding) return;

    const options =
      fBuilding?.options?.filter((o: any) => {
        return o.type !== item_type;
      }) || [];
    commit(SET_ITEMS, {
      building_id,
      type: ItemTypes.PHYSICAL,
      options,
    });
    const itemsCampaignOptions =
      fBuilding?.itemsCampaignOptions?.filter((o: any) => {
        return o.type !== item_type;
      }) || [];
    commit(SET_ITEM_DATES, {
      building_id,
      type: ItemTypes.PHYSICAL,
      itemsCampaignOptions,
    });
    commit(SET_TOTAL, { type: "decrement" });

    try {
      const cache: any =
        JSON.parse(localStorage.getItem(constants.cacheName) as string) || {};
      const activeProject = rootState.project.activeProject;
      if (activeProject in cache) {
        const foundB =
          cache[activeProject].physical.buildings.find(
            (b: any) => b.id === building_id
          ) || null;
        if (foundB) {
          foundB.options =
            foundB?.options?.filter((o: any) => {
              return o.type !== item_type;
            }) || [];
          cache[activeProject].total -= 1;
          localStorage.setItem(constants.cacheName, JSON.stringify(cache));
        }
      }
    } catch (e) {
      console.log(
        "Unable to remove physical item from Shortlist Cache Object",
        e
      );
    }
  },

  [CLEAR_SHORTLIST]({ commit, rootState }: ActionContext<any, any>) {
    commit(SET_EMPTY);
    try {
      const cache: any =
        JSON.parse(localStorage.getItem(constants.cacheName) as string) || {};
      const activeProject =
        rootState.route.params.projectId !== rootState.project.activeProject
          ? rootState.route.params.projectId
          : rootState.project.activeProject;
      if (activeProject in cache) {
        cache[activeProject] = {};
        cache[activeProject].total = 0;
        localStorage.setItem(constants.cacheName, JSON.stringify(cache));
      }
    } catch (e) {
      console.log("Unable to load Request Offer Cache Object", e);
    }
  },

  [EDIT_DIGITAL_FROM_SHORTLIST](
    { commit, rootState }: ActionContext<any, any>,
    {
      oldOption,
      buildingId,
      newType,
    }: { oldOption: any; buildingId: number; newType: string }
  ) {
    const cloneOldOption = { ...oldOption };
    const building =
      rootState.project?.project?.buildings?.find(
        (b: any) => b.id === buildingId
      ) || null;

    if (!building) return;
    let newOption =
      building.packs.find(
        (p: any) => p.duration === oldOption.duration && p.type === newType
      ) || null;

    if (!newOption) return;
    newOption = {
      daily_broadcasts: newOption.daily_broadcasts,
      duration: newOption.duration,
      hourly_broadcasts: newOption.hourly_broadcasts,
      name: newOption.name,
      pack_id: newOption.id,
      price: newOption.price,
      type: newOption.type,
      unique_key: newOption.id,
      items: building?.digitalItems || [],
    };
    commit(SET_EDITED_ITEM, {
      oldOption,
      newOption,
      buildingId: buildingId,
      type: ItemTypes.DIGITAL,
    });

    try {
      const cache: any =
        JSON.parse(localStorage.getItem(constants.cacheName) as string) || {};
      const activeProject = rootState.project.activeProject;
      if (activeProject in cache) {
        const foundB =
          cache[activeProject]?.digital?.buildings?.find(
            (b: any) => b.id === buildingId
          ) || null;
        if (foundB) {
          const optionToReplace =
            foundB?.options?.find(
              (o: any) => o.unique_key === cloneOldOption.unique_key
            ) || null;
          if (optionToReplace) {
            Object.assign(optionToReplace, newOption);
            localStorage.setItem(constants.cacheName, JSON.stringify(cache));
          }
        }
      }
    } catch (e) {
      console.log("Unable to edit digital item from Shortlist Cache Object", e);
    }
  },
};
