import {RouteConfig} from 'vue-router';

const ProjectRoutes = (cfg: any): Array<RouteConfig> => {
    const portfolioPageDisabled = cfg.general.portfolioPageDisabled;
    return [
        {
            path: portfolioPageDisabled ? '/' : '/project',
            redirect: '',
            component: () => import(/* webpackChunkName: "project-template" */ '../templates/ProjectTemplate.vue'),
            children: [
                {
                    path: '',
                    name: portfolioPageDisabled ? 'Home' : 'Project',
                    component: () => import(/* webpackChunkName: "project-page" */ '../views/project/PageProject.vue'),
                    meta: {
                        page: {
                            meta: {
                                ...cfg.project.meta,
                                baseTitle: cfg.general.baseTitle,
                            },
                            sections: cfg.project.sections
                        }
                    }
                },
                {
                    path: ':projectId',
                    name: 'SpecificProject',
                    component: () => import(/* webpackChunkName: "specific-project-page" */ '../views/project/PageProject.vue'),
                    meta: {
                        page: {
                            meta: {
                                ...cfg.project.meta,
                                baseTitle: cfg.general.baseTitle,
                            },
                            sections: cfg.project.sections
                        }
                    }
                },
                {
                    path: ':projectId/space/:spaceId',
                    name: 'SpecificSpace',
                    component: () => import(/* webpackChunkName: "project-space" */ '../views/project/PageSpace.vue'),
                    meta: {
                        page: {
                            meta: {
                                ...cfg.project.meta,
                                baseTitle: cfg.general.baseTitle,
                            },
                            sections: cfg.project.sections
                        }
                    }
                }
            ]
        },
        {
            path: portfolioPageDisabled ? '/embed' : '/embed/project',
            redirect: '',
            component: () => import(/* webpackChunkName: "project-embed-template" */ '../templates/ProjectEmbedTemplate.vue'),
            children: [
                {
                    path: ':projectId',
                    name: 'SpecificProjectEmbed',
                    component: () => import(/* webpackChunkName: "specific-project-embed-page" */ '../views/project/PageProjectEmbed.vue'),
                    meta: {
                        page: {
                            meta: {
                                ...cfg.project.meta,
                                baseTitle: cfg.general.baseTitle,
                            },
                            sections: cfg.project.sections,
                            isEmbedReady: true
                        }
                    }
                },
                {
                    path: ':projectId/space/:spaceId',
                    name: 'SpecificSpaceEmbed',
                    component: () => import(/* webpackChunkName: "project-space" */ '../views/project/PageSpaceEmbed.vue'),
                    meta: {
                        page: {
                            meta: {
                                ...cfg.project.meta,
                                baseTitle: cfg.general.baseTitle,
                            },
                            sections: cfg.project.sections,
                            isEmbedReady: true
                        }
                    }
                }
            ]
        }
    ];
};

export default ProjectRoutes;