// Actions
const LOAD_METADATA = "loadMetaData";
const CHANGE_SIDEBAR_STATE = "changeSidebarState";
const CHANGE_PAGE_LOADING_STATE = "changePageLoadingState";
const TOGGLE_SIDEBAR = "toggleSidebar";
const TOGGLE_CAMPAIGN_SIDEBAR = "toggleCampaignSidebar";
const TOGGLE_REQUEST_OFFER = "toggleRequestOffer";
const TOGGLE_MOBILE_AVAILABILITY_MENU = "toggleMobileAvailabilityMenu";
const CHANGE_MOBILE_AVAILABILITY_MENU = "changeMobileAvailabilityMenu";
const CHANGE_DOCUMENT_READY_STATE = "changeDocumentReadyState";
const TOGGLE_VIRTUAL_TOUR = "toggleVirtualTour";
const TOGGLE_VIRTUAL_TOUR_POPUP = "toggleVirtualTourPopup";
const UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED =
  "updateVirtualTourLastItemClicked";
const UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED =
  "updateVirtualTourLastFloorClicked";
const CLEAR_VIRTUAL_TOUR_POPUP = "clearVirtualTourPopup";

// Mutations
const SET_METADATA = "setMetaData";
const SET_SIDEBAR_STATE = "setSidebarState";
const SET_CAMPAIGN_SIDEBAR_STATE = "setCampaignSidebarState";
const SET_REQUEST_OFFER_STATE = "setRequestOfferState";
const SET_PAGE_LOADING_STATE = "setPageLoadingState";
const SET_MOBILE_AVAILABILITY_MENU = "setMobileAvailabilityMenu";
const SET_DOCUMENT_READY_STATE = "setDocumentReadyState";
const SET_VIRTUAL_TOUR_STATE = "setVirtualTourState";
const SET_VIRTUAL_TOUR_POPUP_STATE = "setVirtualTourPopupState";
const SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED = "setVirtualTourLastItemClicked";
const SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED = "setVirtualTourLastFloorClicked";

// Namespace
const NAMESPACE = "base";

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`;
};

export default {
  action: {
    TOGGLE_SIDEBAR,
    CHANGE_SIDEBAR_STATE,
    TOGGLE_CAMPAIGN_SIDEBAR,
    TOGGLE_REQUEST_OFFER,
    LOAD_METADATA,
    CHANGE_PAGE_LOADING_STATE,
    TOGGLE_MOBILE_AVAILABILITY_MENU,
    CHANGE_MOBILE_AVAILABILITY_MENU,
    CHANGE_DOCUMENT_READY_STATE,
    TOGGLE_VIRTUAL_TOUR,
    TOGGLE_VIRTUAL_TOUR_POPUP,
    CLEAR_VIRTUAL_TOUR_POPUP,
    UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED,
    UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED,
  },
  mutation: {
    SET_SIDEBAR_STATE,
    SET_CAMPAIGN_SIDEBAR_STATE,
    SET_REQUEST_OFFER_STATE,
    SET_METADATA,
    SET_PAGE_LOADING_STATE,
    SET_MOBILE_AVAILABILITY_MENU,
    SET_DOCUMENT_READY_STATE,
    SET_VIRTUAL_TOUR_STATE,
    SET_VIRTUAL_TOUR_POPUP_STATE,
    SET_VIRTUAL_TOUR_LAST_ITEM_CLICKED,
    SET_VIRTUAL_TOUR_LAST_FLOOR_CLICKED,
  },
  namespace: NAMESPACE,
  withNamespace,
};
